import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_divider, { style: {"margin-bottom":"8px","margin-top":"20px"} }),
    _createElementVNode("h3", null, [
      _createTextVNode(" Product Served "),
      _createVNode(_component_a_button, {
        type: "primary",
        size: "small",
        onClick: _ctx.create
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_plus_outlined)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_table, {
      "row-key": "id",
      size: "small",
      columns: _ctx.columns,
      "data-source": _ctx.site.site_products,
      bordered: "",
      style: {"overflow-x":"auto","overflow-y":"auto"}
    }, {
      product: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString((_ctx.all_products.data.find(a => a.value == record.product_id)).label), 1)
      ]),
      tank: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString((_ctx.all_tanks.data.find(a => a.value == record.tank_id)).label), 1)
      ]),
      pump: _withCtx(({ record }) => [
        (record.pumps)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.pumps, (tag) => {
                return (_openBlock(), _createBlock(_component_a_tag, { key: tag }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString((_ctx.all_pumps.data.find(a => a.value == tag)).label), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.site_product_pump, (tag) => {
                return (_openBlock(), _createBlock(_component_a_tag, { key: tag }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString((_ctx.all_pumps.data.find(a => a.value == tag.pump_id)).label), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
      ]),
      action: _withCtx(({ record }) => [
        (_ctx.isNew == false)
          ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: ($event: any) => (_ctx.edit(record)),
                  size: "small"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Edit ")
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_a_popconfirm, {
                  title: "Sure to delete?",
                  onConfirm: ($event: any) => (_ctx.deleteRecord(record))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "danger",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Delete")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onConfirm"])
              ]),
              _: 2
            }, 1024))
          : (_openBlock(), _createBlock(_component_a_space, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: ($event: any) => (_ctx.edit(record)),
                  size: "small"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Edit ")
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_a_button, {
                  type: "danger",
                  size: "small",
                  onClick: ($event: any) => (_ctx.remove(record))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Remove")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024))
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createVNode(_component_Form, {
      ref: "site_product_form",
      title: "Product Served"
    }, null, 512)
  ], 64))
}