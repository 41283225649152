
import { defineComponent, ref, computed, inject, reactive, toRefs, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

interface FormState {
  id:string;
  sub_cluster_id: string;
  site_id: string;
}

const initialState = {
  id:"",
  sub_cluster_id: "",
  site_id: "",
};

export default defineComponent({
  
  setup() {
    /* DATA */
    const store = useStore();
    const router = useRoute();
    const form = reactive<FormState>({...initialState});
    const site = inject<any>("site");
    const visible = ref(false);
    const form_action = ref('save');
    const id = ref(0)


    /* METHODS */
    const create = () => {
      Object.assign(form, initialState);
      visible.value = true;
      form_action.value = 'save'
    };

    const edit = (record:any) => {
      const {  id,sub_cluster_id, site_id} = toRefs(form);
      id.value = record.id;
      sub_cluster_id.value = record.sub_cluster_id;
      site_id.value = record.site_id;
      visible.value = true;
      form_action.value = 'update'
    };

    const save = () => {
      form_action.value == 'save' ? addSubCluster(form) : updateSubCluster(form);
    };

     const addSubCluster = (payload:any) => {
      visible.value = false;
      if(isNew.value == true){
        site.site_sub_clusters.push({
          id: id.value++,
          sub_cluster_id: form.sub_cluster_id,
          site_id: form.site_id,
        });
      }else{
        payload.site_id = site.id;
         store
            .dispatch("SiteSubCluster/store", payload)
            .then((response) => (
              visible.value = false,
              site.site_sub_clusters.push({
                id: response.data.id,
                sub_cluster_id: response.data.sub_cluster_id,
                site_id: response.data.site_id,
              })
            ));
            
      }
      
    };

    const updateSubCluster = (payload:any) => {
       let id = form.id

      if(isNew.value == true){
        site.site_sub_clusters.find(function (value:any, key:any) {
        
          if (value.id == id) {
            site.site_sub_clusters[key] =  Object.assign({}, form);
          }
        });

        visible.value = false
      }else{
        store
          .dispatch("SiteSubCluster/update", payload)
          .then((response) => (
            visible.value = false,
            site.site_sub_clusters.find(function ( value:any,key:any) {
              if (value.id == response.data.id) {
                site.site_sub_clusters[key] =  Object.assign({}, response.data);
              }
            })
          ));
      }
    };

    const cancel = () => {
      visible.value = false
    };

    const getAllSubClusters = () => {
        store.dispatch("Option/getAllSubClusters");
    };

    /* COMPUTED */
 
    const isNew = computed(() =>
      router.name == "retailer-site-details-create" ? true : false
    );
    const all_sub_clusters = computed(() => store.getters["Option/all_sub_clusters"]);
  

    /* MOUNTED */
   onMounted(getAllSubClusters);

    return {
      all_sub_clusters,
      site,
      form,
      visible,
      form_action,
      isNew,
      save,
      create,
      edit,
      cancel,
    };
  },
});
