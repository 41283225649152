
import { defineComponent, ref, inject, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Form from "../components/SubClusterForm.vue"

import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    Form
  },
  setup() {
    const store = useStore();
    const router = useRoute();
    const columns = [
      {
          title: 'Sub Cluster',
          dataIndex: 'label',
          slots: { customRender: "label" },
      },
      {
        title: "Action",
        dataIndex: "action",
        slots: { customRender: "action" },
        align: 'center'
      }
    ];
    const sub_cluster_form = ref<InstanceType<typeof Form>>();
    const site = inject<any>('site');

    const create = () => {
      sub_cluster_form.value?.create();
    };

    const edit = (record: any) => {
      sub_cluster_form.value?.edit(record);
    };

    const remove = (row:any) => {
      site.site_sub_clusters.map(function (value:any, key:any) {
        if (value.id == row.id) {
            site.site_sub_clusters.splice(key, 1);
        }
      });
    };

    const deleteRecord = (row:any) => {
      store.dispatch("SiteSubCluster/destroy", row.id).then((response) => (
        site.site_sub_clusters.map(function (value:any, key:any) {
          if (value.id == response.data) {
              site.site_sub_clusters.splice(key, 1);
          }
        })
      ));
      
    };
    const getAllSubClusters = () => {
        store.dispatch("Option/getAllSubClusters");
    };


    const isNew = computed(() =>
      router.name == "retailer-site-details-create" ? true : false
    );
    const all_sub_clusters = computed(() => store.getters["Option/all_sub_clusters"]);

    return {
        columns,
        site,
        sub_cluster_form,
        all_sub_clusters,
        isNew,
        create,
        edit,
        remove,
        deleteRecord
    };
  },
});
