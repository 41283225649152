
import { defineComponent, ref,reactive,computed,onMounted,UnwrapRef,provide } from "vue";
import { useStore } from 'vuex';
import SubCluster from './components/SubCluster.vue'
import SiteProduct from './components/SiteProduct.vue'

interface FormState {
  retailer_id: string;
  arnoc: string;
  ship_to: string;
  site_name: string;
  nhp_dealer: any;
  nti: any;
  territory_manager_id: string;
  cluster_id:string;
  zone_id:string;
  id:string;
  site_products: any;
  remove_site_products:any;
  site_sub_clusters: any;
  remove_site_sub_clusters:any;
  type: string;
  retailer_name: string;
}

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        },
    },
    components: {
        SubCluster,
        SiteProduct
    },
    setup(props){
        const store = useStore();
        const form: UnwrapRef<FormState> = reactive({
            id: "",
            retailer_id:"",
            arnoc:"",
            ship_to:"",
            site_name:"",
            nhp_dealer:"0",
            nti:"0",
            territory_manager_id:"",
            cluster_id:"",
            zone_id:"",
            type:"",
            retailer_name:"",
            site_products: [
                // {
                //     product_id: "",
                //     plant_id:"",
                //     site_id:"",
                //     tank_id:"",
                //     pump_id:""
                // },
            ],
            remove_site_products:[],
            site_sub_clusters: [
                // {
                //     site_id:"",
                //     sub_cluster_id:""
                // }
            ],
            remove_site_sub_clusters:[],
        });
        provide('site',form);

        const checked_nhp = ref(false);
        const checked_nti = ref(false);
        const full_district = ref('');
        const district_manager = ref('');

        const onChangeNhpDealer = (e:any) => {
            checked_nhp.value = e.target.checked;
            form.nhp_dealer = checked_nhp.value == false ? '0': '1'
        };
        const onChangeNti = (e:any) => {
            checked_nti.value = e.target.checked;
            form.nti = checked_nti.value == false ? '0': '1'
        };

        function addRowSubCluster(){
            form.site_sub_clusters.push({
                site_id:"",
                sub_cluster_id:"",
                id:""
            });
        }

        function deleteRowSubCluster(row:any,id:any){
            form.remove_site_sub_clusters.push({
                id:id
            });
            form.site_sub_clusters.splice(row, 1);
        }

        function addRowProducts() {
            form.site_products.push({
                product_id: "",
                plant_id: "",
                site_id:"",
                tank_id:"",
                pump_id:"",
                id:""
            });
        }

        function deleteRowProducts(row: any,id:any) {
            form.remove_site_products.push({
                id:id
            });
            form.site_products.splice(row, 1);
        }


        const getZone = (id:any) => {
            store.dispatch("Zone/show",id).then((res) => (
                form.territory_manager_id = res.data.territory_manager_id,
                full_district.value = res.data.district.district_name,
                district_manager.value = res.data.district.district_manager.full_name
                
            ));
        };

        const getSite = () => {
            if (props.id) {
                store
                .dispatch("RetailerSites/show",props.id)
                .then(
                    (res) => (
                        (form.id = res.data.id),
                        (form.retailer_id = res.data.retailer_id),
                        // (form.retailer_name = res.data.retailer.user_name),
                        (form.arnoc = res.data.arnoc),
                        (form.ship_to = res.data.ship_to),
                        (form.site_name = res.data.site_name),
                        (form.zone_id = res.data.zone_id),
                        (form.territory_manager_id = res.data.territory_manager_id),
                        (form.cluster_id = res.data.cluster_id),
                        (form.site_products = res.data.site_products),
                        (form.site_sub_clusters = res.data.site_sub_clusters),
                        (form.type = res.data.type),
                        (form.nhp_dealer = res.data.nhp_dealer),
                        (form.nti = res.data.nti),
                        checked_nhp.value = res.data.nhp_dealer == 1 ?true:false,
                        checked_nti.value = res.data.nti == 1 ?true:false,
                        form.retailer_name = res.data.retailer.full_name,
                        full_district.value = res.data.zone.district.district_name,
                        district_manager.value = res.data.zone.district.district_manager.full_name
                       
                    )
                    
                );
            }
                
            
        };

        const getAllZones = () => {
            store.dispatch("Option/getAllZones");
        };
        const getRTM = () => {
            store.dispatch("Option/getAllRetailTerritoryManagers");
        };
        const getAllSubClusters = () => {
            store.dispatch("Option/getAllSubClusters");
        };
        const getAllProducts = () => {
            store.dispatch("Option/getAllProducts");
        };
        const getAllTanks = () => {
            store.dispatch("Option/getAllTanks");
        };
        const getAllPumps = () => {
            store.dispatch("Option/getAllPumps");
        };
        const getAllClusters = () => {
            store.dispatch("Option/getAllClusters");
        };

        const handleFinish = async (values: FormState) => {
            // console.log(form)
            if (props.id) {
                form.id = props.id;
                store.dispatch("RetailerSites/update", form);
            } else {
                store.dispatch("RetailerSites/store", form);
            }
            
        };

        const all_zones = computed(() => store.getters["Option/all_zones"]);
        const retail_territory_managers = computed(() => store.getters["Option/all_retail_territory_managers"]);
        const all_sub_clusters = computed(() => store.getters["Option/all_sub_clusters"]);
        const all_tanks = computed(() => store.getters["Option/all_tanks"]);
        const all_pumps = computed(() => store.getters["Option/all_pumps"]);
        const all_products = computed(() => store.getters["Option/all_products"]);
        const all_clusters = computed(() => store.getters["Option/all_clusters"]);
        const auth_user = computed(() => store.state.AuthUser.user);

        onMounted(() => {
            getSite();
            getAllZones();
            getRTM();
            getAllClusters();
        });

        return {
            form,
            all_zones,
            retail_territory_managers,
            all_sub_clusters,
            all_products,
            all_tanks,
            all_pumps,
            all_clusters,
            checked_nti,
            checked_nhp,
            full_district,
            district_manager,
            onChangeNhpDealer,
            onChangeNti,
            getZone,
            deleteRowSubCluster,
            addRowSubCluster,
            addRowProducts,
            deleteRowProducts,
            handleFinish
        }
    }
  
});
